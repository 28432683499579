@font-face {
    font-family: 'PostGrotesk-Bold';
    src: url('fonts/PostGrotesk-Bold.eot');
    src: url('fonts/PostGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/PostGrotesk-Bold.woff2') format('woff2'),
        url('fonts/PostGrotesk-Bold.woff') format('woff'),
        url('fonts/PostGrotesk-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PostGrotesk-Light';
    src: url('fonts/PostGrotesk-Light.eot');
    src: url('fonts/PostGrotesk-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/PostGrotesk-Light.woff2') format('woff2'),
        url('fonts/PostGrotesk-Light.woff') format('woff'),
        url('fonts/PostGrotesk-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'PostGrotesk-Medium';
    src: url('fonts/PostGrotesk-Medium.eot');
    src: url('fonts/PostGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/PostGrotesk-Medium.woff2') format('woff2'),
        url('fonts/PostGrotesk-Medium.woff') format('woff'),
        url('fonts/PostGrotesk-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'PostGrotesk-Regular';
    src: url('fonts/PostGrotesk-Regular.eot');
    src: url('fonts/PostGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/PostGrotesk-Regular.woff2') format('woff2'),
        url('fonts/PostGrotesk-Regular.woff') format('woff'),
        url('fonts/PostGrotesk-Regular.ttf') format('truetype');
}

$background-light: #E8E6E4;
$dark-blue: #2C5ED0;
/*$contactBar-width: 40vw; */
$contactBar-width: 450px;
$break-point: 767px;

@mixin transition(){
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

body{
  font-family: 'PostGrotesk-Light';
  font-size: 1.1rem;
  max-width: 100vw;
  overflow-x: hidden;
  line-height: 1.1;
}

::selection {
  color: white;
  background: $dark-blue;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: #011121;
}

h1,h2,h3,h4,h5{
  font-family: 'PostGrotesk-Medium';
}

h1{
  font-size: 3.5rem;
  @media (max-width: $break-point){
    font-size: 9vw;
  }
}

button{
  background: none;
  border: none;
  &:focus{
      outline:none;
  }
}
.container{
  padding-right: 0px;
  padding-left: 0px;
  @media (min-width: 1200px){
    max-width: 1280px;
  }
  @media (max-width: 1200px){
    max-width: inherit;
  }
}

.info-modal {
  width: 100vw;
  position: fixed;
  bottom: 15px;
  text-align: center;
  z-index: 1;
  > div{
    padding: 0px 10px 15px 10px;
    background-color: white;
    width: 350px;
    max-width: 100%;
    margin: auto;
    @media (max-width: $break-point) {
      background-color: $background-light;
    }
  }
  &__content{
    a{
      font-family: 'PostGrotesk-Regular';
      color: gray;
    }
    display: flex;
    flex-flow: row wrap;
    height: 70%;
    align-content: center;
  }
}

.blueBg{
  background-color: $dark-blue;
}
.grayBg{
  background-color: $background-light;
}

$padding-top: 25vh;

.padding-top{
  padding-top: $padding-top;
}


.no-bg-xs{
  @media (max-width: $break-point) {
    background-image: none !important;
  }
}

.two-columns{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    .padding-top{
      padding-top: $padding-top;
    }
    > div{
      flex: 1;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 10%;
    }
    @media (max-width: $break-point) {
      .padding-top:last-child{
        padding-top: 20px;
      }
      .padding-top{
        padding-top: 15vh;
      }
      flex-wrap: wrap;
      flex-direction: column;
      &_warp-reverse{
        flex-direction: column-reverse;
      }
    }
}

.two-columns_fullHeight{
  min-height: 100vh;
}

.main-container{
  display: flex;
  position: relative;
  min-width: 100vw;
  left: 0vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @include transition;

  &_sidebar{
    @include transition;
    left: -$contactBar-width;
    width: calc(100vw + #{$contactBar-width});
  }
  &__main-content{
    min-height: 100vh;
    min-width: 100vw;
    position: relative;
    overflow: hidden;
  }
}

.sideBar{
  height: 100vh;
  z-index: 2;
  min-width: $contactBar-width;
  background-color: $background-light;
  font-family: 'PostGrotesk-Regular';
  a{
    font-family: 'PostGrotesk-Light';
    color: $dark-blue;
  }
  &_shadow{
    -webkit-box-shadow: -9px 1px 18px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: -9px 1px 18px -4px rgba(0,0,0,0.75);
    box-shadow: -9px 1px 18px -4px rgba(0,0,0,0.75);
  }
  &_equipo{
    position: absolute;
    top: 0;
    width: $contactBar-width;
    height: 100vh;
    right: 0px;
  }
  &_mobile{
    min-width: calc(100vw - 20px);
    min-height: calc(100vh - 20px);
    position: fixed;
    margin: 10px;
  }
  &__header{
    display: flex;
    justify-content: space-between;

  }
  &__closeBtn{

  }
}

.sideBar-fade-enter {
  right: -600px;
}

.sideBar-fade-enter.sideBar-fade-enter-active {
  right: 0px;
  transition: right 500ms ease-in;
}

.sideBar-fade-leave {
  right: 0px;
}

.sideBar-fade-leave.sideBar-fade-leave-active {
  right: -600px;
  transition: right 300ms ease-in;
}



.menu-bar {
  font-family: 'PostGrotesk-Medium';
  font-size: 1.1vw;
  z-index: 2;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 20px;
  @media (min-width: 1200px){
    font-size: .85rem;
  }
  @media (max-width: $break-point) {
    top: 0px;
    position: fixed;
  }
  svg path, svg rect{
    stroke: none;
    fill: $dark-blue;
  }
  &_mobileInverse:not(.active){
    svg path, svg rect{
      fill: white;
    }
  }
  &_bgcolor_white{
    background: white;
  }
  .navbar-toggle{
    border: none;
  }
  &__hamburgerSlider span,
  &__hamburgerSlider_active span,
  &__hamburgerSlider span:before,
  &__hamburgerSlider_active span:before,
  &__hamburgerSlider_active span:after,
  {
    background-color: $dark-blue;
  }
  &__hamburgerSlider.mobileInverse span,
  &__hamburgerSlider.mobileInverse span:before
  {
    background-color: white;
  }
  &__hamburgerSlider span{
    top: 10px;
  }
  &__hamburgerSlider span:after{
    background-color: transparent;
  }
  &__menu-overlay{
    position: fixed;
    background-color: $background-light;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0px;
  }

  &__menu-desplegable {
    display: contents;
    @media (max-width: $break-point) {
      left: 0;
      position: absolute;
      width: 100%;
      top: 85px;
      font-size: 25px;
      z-index: 20;
      display: inline;
      a, .navbar-nav{
        color: #2C5ED0;
      }
      .col:not(:last-child) .show{
        border-bottom: 1px solid $dark-blue;
      }
      .col:last-child .show{
        padding-top: 40px;
      }
    }
  }
}

.category{
  &__title{
    cursor: pointer;
    font-family: 'PostGrotesk-Medium';
  }
  &__list{
    @include transition;
    max-height: 0px;
    overflow-y: hidden;
  }
  &__list_active{
    @include transition;
    max-height: 100vh;
    overflow-y: auto;
    cursor: pointer;
  }
  &__list_selected{
    font-family: 'PostGrotesk-Bold';
  }

}


.picturesGrid{
  min-height: 200px;
  &__item{

    margin-bottom: 10px;
    width: 50%;
    display: block;
    float: left;
    box-sizing: border-box;
    text-align: center;
    img{
      width: 100%;
      margin-bottom: 10px;
      padding-right: 10px;
      height: auto;
    }
  }
  &__item:nth-child(2n + 0){
    float: right;
    img{
      padding-left: 10px;
      padding-right: 0px;
    }
  }
}

.instalacionesGrid_animation-enter {
  opacity: 0.01;
}

.instalacionesGrid_animation-enter.instalacionesGrid_animation-enter-active {
  opacity: 1;
  transition: opacity 700ms ease-in;
}

.instalacionesGrid_animation-leave {
  opacity: 1;
}

.instalacionesGrid_animation-leave.instalacionesGrid_animation-leave-active {
  opacity: 0.01;
  transition: opacity 10ms ease-in;
}

.carousel-slide-one{
  @media (min-width: $break-point){
    padding-left: 20% !important;
    padding-right: 10% !important;
  }
}

.carousel-item {
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.carousel-control-prev,
.carousel-control-next{
  top: 50vh;
  width: 8%;
  align-items: baseline;
}


.carousel-control-prev-icon {
  height: 65px;
  width: 37px;
  background-image: url(./img/slider_left_arrow.png);
}

.carousel-control-next-icon {
  height: 65px;
  width: 37px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  background-image: url(./img/slider_left_arrow.png);
}


form.doctores-form{
  div{
    border-bottom: 1px solid white;
    margin-bottom: 20px;
    label{
      margin: 0px;
      padding-right: 15px;
    }
    input,
    textarea{
      background: none;
      border: none;
      padding: 0px;
      color: white;
    }
    input:focus,
    textarea:focus{
      outline: none;
    }
    textarea{
      resize: none;
    }
  }
}
